import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useRef, useState } from 'react';
import { Configurator, ConfiguratorSetup } from './Configurator';
import { createInitConfiguration } from '../widget/thunks/init/createInitConfiguration';
import { ConfigProvider } from 'antd';
import theme from 'antd/es/theme';
import { useLocation } from 'react-router-dom';
import { setProductId } from '../widget/Widget.slice';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { ROUTE_PRODUCTS } from '../wizard/wizard.router';
import { useErrorBoundary } from 'react-error-boundary';

export const ConfiguratorPage = (): JSX.Element => {
    const threeKitId = useAppSelector((state) => state.widget.threeKitId);
    const initialized = useAppSelector((state) => state.widget.initialized);
    const uuid = useAppSelector((state) => state.widget.uuid);
    const prevUuid = useRef<string>();

    const [configuratorSetup, setConfiguratorSetup] = useState<ConfiguratorSetup>();
    const configurationFields = useAppSelector((state) => state.widget.configurationFields);
    const configurationMessages = useAppSelector((state) => state.widget.configurationMessages);
    const dispatchingFetch = useRef<boolean>(false);
    const dispatchingInit = useRef<boolean>(false);
    const { state } = useLocation();
    const productId = useAppSelector((state) => state.widget.productId);
    const dispatch = useAppDispatch();
    const tooltips = useAppSelector((state) => state.widget.tooltips);
    const navigate = useNavigateExtended();
    const { showBoundary } = useErrorBoundary();

    useEffect(() => {
        if (initialized) {
            if (uuid && !dispatchingFetch.current && (!configuratorSetup || prevUuid.current !== uuid)) {
                dispatchingFetch.current = true;
                setConfiguratorSetup({
                    fields: Object.values(configurationFields),
                    messages: configurationMessages,
                    uuid: uuid,
                    threeKitId: threeKitId,
                    tooltips,
                });
                dispatchingFetch.current = false;
            } else if (!uuid && !dispatchingInit.current && (state?.productId || productId)) {
                dispatchingInit.current = true;
                dispatch(setProductId(state?.productId));
                dispatch(createInitConfiguration()).unwrap().catch(showBoundary);
            } else if (!dispatchingInit.current && !dispatchingFetch.current && !configuratorSetup) {
                navigate('/' + ROUTE_PRODUCTS, { replace: true });
            }

            prevUuid.current = uuid;
        }
    }, [
        configurationFields,
        configurationMessages,
        configuratorSetup,
        dispatch,
        initialized,
        navigate,
        productId,
        showBoundary,
        state?.productId,
        threeKitId,
        tooltips,
        uuid,
    ]);

    return (
        <ConfigProvider theme={theme.defaultConfig}>
            {configuratorSetup && <Configurator {...configuratorSetup} />}
        </ConfigProvider>
    );
};
