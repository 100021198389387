import { notification } from 'antd';
import { ValidateErrorEntity } from 'rc-field-form/es/interface';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import logger from '../../app/logger';
import { Page } from '../../shared/components/layout/Page';
import { GLatLng } from '../../shared/google/models/GAddress';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { useScreenChange } from '../../shared/hooks/screen';
import { useLogikForm } from '../../shared/logik/hooks/form.hooks';
import { FormState } from '../../shared/models/form.state.model';
import { fetchConfiguration } from '../widget/thunks/basic/fetchConfiguration';
import { setUserProfile } from '../widget/thunks/init/setUserProfile';
import { ROUTE_CONFIGURATOR, ROUTE_SUMMARY } from '../wizard/wizard.router';
import { AddressFormShipping } from './AddressFormShipping';
import { CustomInfoForm } from './CustomerInfoForm';
import styles from './CustomerPage.module.scss';
import { setSaving } from '../widget/Widget.slice';
import { AddressFormBilling } from './AddressFormBilling';
import { updateTaxRate } from '../tax/thunks/updateTaxRateThunk';
import { isError } from 'lodash';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';

export type CustomerFormState = FormState;

export const CustomerPage = (): JSX.Element => {
    const navigate = useNavigateExtended();
    const [customerInfoForm] = useLogikForm<CustomerFormState>();
    const [shippingAddressForm] = useLogikForm<CustomerFormState>();
    const [billingAddressForm] = useLogikForm<CustomerFormState>();
    const { device } = useScreenChange();
    const dispatch = useAppDispatch();
    const uuid = useAppSelector((state) => state.widget.uuid);
    const [deliveryLatLng, setDeliveryLatLng] = useState<GLatLng>();
    const [updating, setUpdating] = useState(false);
    const customerName = useFieldValue<string>(LogikFieldsEnum.cusName);
    const shippingContact = useFieldValue<string>(LogikFieldsEnum.shipping_contact);
    const [shippingNameIsDifferent, setShippingNameIsDifferent] = useState<boolean>(false);

    useEffect(() => {
        if (!!shippingContact && !!customerName && shippingContact != customerName) {
            setShippingNameIsDifferent(true);
        }
    }, []);

    const updateUseCustomerName = useCallback(async () => {
        if (!shippingNameIsDifferent && !!customerName) {
            shippingAddressForm.setFieldValue(LogikFieldsEnum.shipping_contact, customerName);
            await dispatch(
                updateConfiguration({
                    updates: [
                        {
                            variableName: LogikFieldsEnum.shipping_contact,
                            value: customerName,
                        },
                    ],
                }),
            ).unwrap();
        }
    }, [customerName, dispatch, shippingNameIsDifferent, shippingAddressForm]);

    const onNext = useCallback(() => {
        try {
            Promise.all([
                customerInfoForm.validateFields(),
                shippingAddressForm.validateFields(),
                billingAddressForm.validateFields(),
            ])
                .then(async () => {
                    await updateUseCustomerName();
                })
                .then(async () => {
                    if (uuid) {
                        try {
                            dispatch(setSaving(true));
                            setUpdating(true);
                            await Promise.all([
                                dispatch(setUserProfile(uuid)),
                                dispatch(
                                    updateTaxRate({
                                        latLng: deliveryLatLng,
                                    }),
                                ).unwrap(),
                            ]);
                            await dispatch(fetchConfiguration(uuid)).unwrap();
                        } catch (err) {
                            notification.error({
                                message: isError(err) ? err.message : 'Unknown error occurred',
                                duration: 5,
                                placement: 'bottomLeft',
                            });
                        } finally {
                            setUpdating(false);
                            dispatch(setSaving(false));
                        }
                    }
                    navigate('../' + ROUTE_SUMMARY, { replace: true });
                })
                .catch((errorInfo: ValidateErrorEntity<CustomerFormState>) => {
                    billingAddressForm.scrollToField(errorInfo.errorFields[0].name[0]);
                });
        } catch (err: unknown) {
            logger.error(err);
        }
    }, [
        billingAddressForm,
        customerInfoForm,
        deliveryLatLng,
        dispatch,
        navigate,
        shippingAddressForm,
        updateUseCustomerName,
        uuid,
    ]);

    return (
        <Page
            navProps={{
                onClickBack: () => navigate('../' + ROUTE_CONFIGURATOR, { replace: true }),
                onClickNext: onNext,
                disableNext: updating,
                nextButtonTitle: device === 'mobile' ? 'To Purchase Opt.' : 'Next to Purchase Options',
                relatedForms: [customerInfoForm, shippingAddressForm, billingAddressForm],
            }}>
            <div className={styles['customer-page-content']}>
                <CustomInfoForm form={customerInfoForm} device={device} />
                <AddressFormShipping
                    form={shippingAddressForm}
                    device={device}
                    onSaveShippingCoordinates={setDeliveryLatLng}
                    shippingNameIsDifferent={shippingNameIsDifferent}
                    setShippingNameIsDifferent={setShippingNameIsDifferent}
                />
                <AddressFormBilling form={billingAddressForm} device={device} />
            </div>
        </Page>
    );
};
