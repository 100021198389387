import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, notification, Switch } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import logger from '../../app/logger';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import AddressAutocomplete, { AddressAutocompleteRefType } from '../../shared/google/components/AddressAutocomplete';
import { GAddress, GLatLng } from '../../shared/google/models/GAddress';
import { placeResultToAddress } from '../../shared/google/utils/address.utils';
import { LogikFormItem } from '../../shared/logik/components/LogikFormItem';
import { LogikSelect } from '../../shared/logik/components/LogikSelect';
import { LogikFields, LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { LogEntry } from '../../shared/models/logentry';
import { AddressMapModal } from './AddressMapModal';
import { CustomerFormState } from './CustomerPage';
import styles from './AddressForm.module.scss';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { TerritoryChangeModal } from './TerritoryChangeModal';
import { getPhoneInputValidators, PhoneInput, PhoneInputRef } from '../../shared/antd/components/PhoneInput';
import { DeviceType } from '../../shared/hooks/screen';
import { getZipCodeInputValidators, ZipCodeInput, ZipCodeInputRef } from '../../shared/antd/components/ZipCodeInput';
import { useNavigate } from 'react-router-dom';
import { ROUTE_CONFIGURATOR } from '../wizard/wizard.router';
import { LogikForm } from '../../shared/logik/components/LogikForm';
import { LogikInput } from '../../shared/logik/components/LogikInput';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateTaxRate } from '../tax/thunks/updateTaxRateThunk';
import { isError } from 'lodash';
import { getOrderDateUpdate } from '../wizard/wizard.helpers';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';

const type = 'shipping';
type AddressFormProps = {
    form: FormInstance<CustomerFormState>;
    device: DeviceType;
    onSaveShippingCoordinates?: (latLng: GLatLng) => void;
    shippingNameIsDifferent: boolean;
    setShippingNameIsDifferent: (state: boolean) => void;
};
export const AddressFormShipping = ({
    form,
    device,
    onSaveShippingCoordinates,
    shippingNameIsDifferent,
    setShippingNameIsDifferent,
}: AddressFormProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [modalHandle, setModalHandle] = useState<FlexibleModalHandle<typeof AddressMapModal>>();
    const [terrModalHandle, setTerrModalHandle] = useState<FlexibleModalHandle<typeof TerritoryChangeModal>>();
    const territory = useFieldValue<string>(LogikFieldsEnum.territory);
    const [prevTerritory, setPrevTerritory] = useState<string>();
    const utcDate = useFieldValue<string>(LogikFieldsEnum.orderDateTimeUTC);
    const uuid = useAppSelector((state) => state.widget.uuid);
    const siteId = useFieldValue<string>(LogikFieldsEnum.siteId);

    const address1 = LogikFields.getLogikFieldNameAndRequire(`${type}_address`);
    const city = LogikFields.getLogikFieldNameAndRequire(`${type}_city`);
    const state = LogikFields.getLogikFieldNameAndRequire(`${type}_state`);
    const zip = LogikFields.getLogikFieldNameAndRequire(`${type}_zip`);
    const country = LogikFields.getLogikFieldNameAndRequire(`${type}_country`);
    const phone = LogikFields.getLogikFieldNameAndRequire(`${type}_primary_phone`);

    const autocompleteRef = useRef<AddressAutocompleteRefType>(null);
    const phoneInputRef = useRef<PhoneInputRef>(null);
    const zipInputRef = useRef<ZipCodeInputRef>(null);

    const { setFieldValue, validateFields } = form;

    useEffect(() => {
        if (!modalHandle) {
            const modal = FlexibleModal.createModal(AddressMapModal, { destroyOnClose: true });

            setModalHandle(modal);
        }
    }, [modalHandle]);

    useEffect(() => {
        if (!terrModalHandle) {
            const modal = FlexibleModal.createModal(TerritoryChangeModal, { destroyOnClose: false });

            setTerrModalHandle(modal);
        }
    }, [terrModalHandle]);

    useEffect(() => {
        if (territory) {
            setFieldValue(LogikFieldsEnum.territory, territory);
            validateFields([LogikFieldsEnum.territory]);
        }

        if (!!prevTerritory && !!territory && prevTerritory !== territory) {
            if (terrModalHandle) {
                terrModalHandle.open({
                    onRequestClose: () => {
                        terrModalHandle.close().then(() => {});
                    },
                    onRequestCloseAndRedirect: () => {
                        terrModalHandle
                            .close()
                            .then(() => {
                                if (utcDate && siteId) {
                                    return dispatch(
                                        updateConfiguration({
                                            uuid,
                                            updates: [getOrderDateUpdate(utcDate, siteId)],
                                        }),
                                    ).unwrap();
                                } else {
                                    return Promise.reject('No utc date in configuration');
                                }
                            })
                            .then(() => {
                                navigate('../' + ROUTE_CONFIGURATOR, { replace: true });
                            });
                    },
                });
            }
        }

        if (!!territory && !prevTerritory) {
            setPrevTerritory(territory);
        }
    }, [
        dispatch,
        navigate,
        prevTerritory,
        setFieldValue,
        siteId,
        terrModalHandle,
        territory,
        utcDate,
        uuid,
        validateFields,
    ]);

    const updateTax = useCallback(
        async (latLng?: GLatLng) => {
            await dispatch(
                updateTaxRate({
                    latLng: latLng,
                }),
            )
                .unwrap()
                .catch((err) => {
                    notification.error({
                        message: isError(err) ? err.message : 'Unknown error occurred',
                        duration: 5,
                        placement: 'bottomLeft',
                    });
                });
        },
        [dispatch],
    );

    const fillAddress = useCallback(
        (address: GAddress): void => {
            const changes: Partial<CustomerFormState> = {};

            changes[address1] = address.address ?? '';
            changes[city] = address.city ?? '';
            changes[state] = address.state ?? '';
            changes[zip] = address.zip ?? '';
            changes[country] = address.country ?? '';

            form.setFields(
                Object.entries(changes).map(([k, v]) => ({
                    name: k,
                    value: v,
                    touched: true,
                })),
            );

            modalHandle?.close();

            if (!!address.coordinates) {
                updateTax().then(() => {
                    if (address.coordinates) onSaveShippingCoordinates?.(address.coordinates);
                });
            }
        },
        [address1, city, country, form, modalHandle, onSaveShippingCoordinates, state, updateTax, zip],
    );

    const autocompleteAddress = (place: google.maps.places.PlaceResult): void => {
        if (place.address_components) {
            const address = placeResultToAddress(place);
            fillAddress(address);
        }
    };

    const showModal = useCallback(() => {
        const address = LogikFields.getLogikFieldName(`${type}_address`);
        const city = LogikFields.getLogikFieldName(`${type}_city`);
        const state = LogikFields.getLogikFieldName(`${type}_state`);
        const zip = LogikFields.getLogikFieldName(`${type}_zip`);

        if (address && city && state && zip && modalHandle) {
            const defaultAddress: GAddress = {
                address: form.getFieldValue(address),
                city: form.getFieldValue(city),
                state: form.getFieldValue(state),
                zip: form.getFieldValue(zip),
            };

            modalHandle.open({
                onOk: fillAddress,
                onCancel: () => modalHandle?.close(),
                defaultAddress,
            });
        } else {
            logger.error(new LogEntry('Logik field not found for one of:', { address, city, state, zip }));
        }
    }, [fillAddress, form, modalHandle]);

    const validateTerritory = useCallback(
        (_: unknown, value: string) => {
            {
                if (!value || !prevTerritory) return Promise.resolve();
                if (value == prevTerritory) {
                    return Promise.resolve();
                } else {
                    return Promise.reject();
                }
            }
        },
        [prevTerritory],
    );

    return (
        <LogikForm
            name={`${type}-address-form`}
            form={form}
            layout={device === 'mobile' || device === 'tablet' ? 'vertical' : 'horizontal'}
            labelCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 8 }}
            wrapperCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 16 }}
            labelAlign='left'
            requiredMark={true}
            initialValues={{ [LogikFieldsEnum.billing_use_shipping]: false }}
            autoComplete='on'>
            <div className='ant-form-header'>Shipping Info</div>
            <LogikFormItem
                logikName=''
                direction='horizontal'
                labelCol={{ span: device === 'mobile' || device === 'tablet' ? 'auto' : 22 }}
                wrapperCol={{ span: device === 'mobile' || device === 'tablet' ? 4 : 2 }}
                label={<span style={{ whiteSpace: 'nowrap', overflow: 'visible' }}>Ship to different name:</span>}>
                <Switch checked={shippingNameIsDifferent} onChange={setShippingNameIsDifferent} />
            </LogikFormItem>

            {shippingNameIsDifferent && (
                <LogikFormItem
                    logikName={LogikFieldsEnum.shipping_contact}
                    label='Customer Name'
                    rules={[
                        {
                            required: true,
                            message: 'Customer name is required',
                        },
                    ]}
                    withDebounce={true}>
                    <LogikInput placeholder='Enter your name' autoComplete='name' />
                </LogikFormItem>
            )}

            <div className={styles['map-field-container']}>
                <LogikFormItem
                    logikName={address1}
                    label='Address'
                    rules={[
                        {
                            required: true,
                            message: 'Address is required',
                        },
                    ]}
                    withDebounce={false}>
                    <AddressAutocomplete
                        ref={autocompleteRef}
                        onPlaceChanged={(pl) => autocompleteAddress(pl)}
                        valueTransform={(v, p) => p.structured_formatting.main_text}
                        inputProps={{
                            suffix: (
                                <Button
                                    type='text'
                                    icon={<EnvironmentOutlined />}
                                    onClick={() => {
                                        showModal();
                                        setTimeout(() => autocompleteRef.current?.blur(), 0);
                                    }}
                                    className={styles['map-btn'] + ' ignore-focus-on-enter'}
                                />
                            ),
                            placeholder: `Enter your ${type} address`,
                            autoComplete: 'street-address',
                        }}
                    />
                </LogikFormItem>
            </div>
            <LogikFormItem
                logikName={city}
                label='City'
                rules={[
                    {
                        required: true,
                        message: 'City is required',
                    },
                ]}
                withDebounce={true}>
                <LogikInput autoComplete='address-level2' disabled />
            </LogikFormItem>

            <LogikFormItem
                logikName={state}
                label='State'
                rules={[
                    {
                        required: true,
                        message: 'State is required',
                    },
                ]}>
                <LogikSelect
                    fieldName={state}
                    showSearch
                    searchByValueAndLabel
                    filterOption={(input, option) =>
                        (option?.label ?? '').toString().toLowerCase().includes(input.toLowerCase())
                    }
                    placeholder='Enter your state'
                    autoComplete='address-level1'
                    disabled
                />
            </LogikFormItem>

            <LogikFormItem
                logikName={zip}
                label='Zip Code'
                rules={[
                    {
                        required: true,
                        message: 'Zip Code is required',
                    },
                    ...getZipCodeInputValidators(zipInputRef),
                ]}
                updateOnBlur
                updateOnMaskComplete>
                <ZipCodeInput
                    placeholder='Enter your zip code'
                    ref={zipInputRef}
                    autoComplete='postal-code'
                    onBlur={() => updateTax()}
                    disabled
                />
            </LogikFormItem>

            <LogikFormItem
                label='Phone'
                logikName={phone}
                updateOnBlur
                updateOnMaskComplete
                rules={[{ required: true, message: 'Phone is required' }, ...getPhoneInputValidators(phoneInputRef)]}>
                <PhoneInput ref={phoneInputRef} autoComplete='tel' />
            </LogikFormItem>

            <Form.Item
                className='ant-form-item-only-error'
                name={LogikFieldsEnum.territory}
                wrapperCol={{ span: 24 }}
                rules={[
                    {
                        validator: validateTerritory,
                        message: (
                            <span style={{}}>
                                Selected zip code belongs to a different territory. In order to continue you must enter
                                the same zip code as on the first step or go back to configurator and check options that
                                are not available in the new territory
                            </span>
                        ),
                    },
                ]}>
                <Input type='hidden' />
            </Form.Item>
        </LogikForm>
    );
};
